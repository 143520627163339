"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QRCodeLoginHub = void 0;
const signalr_1 = require("@aspnet/signalr");
class QRCodeLoginHub {
    constructor() {
        this.connection = new signalr_1.HubConnectionBuilder()
            .withUrl("/QRCodeLoginHub")
            .configureLogging(signalr_1.LogLevel.None)
            .build();
    }
    startConnection(callback) {
        this.connection
            .start()
            .then(callback)
            .catch(err => {
            console.log(err);
            setTimeout(() => this.startConnection(callback), 5000);
        });
    }
    stopConnection(callback) {
        this.connection.stop().then(callback);
    }
    onCloseConnection(callback) {
        this.connection.onclose(callback);
    }
    invokeGetConnectionId(callback) {
        this.connection.invoke("getConnectionId").then(callback);
    }
    onShowUserLoginInfo(callback) {
        this.connection.on("showUserLoginInfo", callback);
    }
    onConfirmLogin(callback) {
        this.connection.on("confirmLogin", callback);
    }
}
exports.QRCodeLoginHub = QRCodeLoginHub;
