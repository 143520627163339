"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignalRClient = void 0;
const qrcode_login_hub_1 = require("./qrcode-login.hub");
class SignalRClient {
    constructor() {
        this._startConnection = () => {
            this._qrcodeHub.invokeGetConnectionId(connectionId => {
                this._domElements.connectionIdHiddenField.val(connectionId);
                this._getImageData(connectionId);
            });
        };
        this._getImageData = (connectionId) => {
            $.ajax({
                method: "GET",
                url: `/api/v1/mobile/auth/qrcode/?connectionId=${connectionId}`
            }).then((qrcodeImage) => {
                this._domElements.qrCodeImage.attr("src", qrcodeImage.data);
                this._domElements.userInfoContent.hide();
                this._domElements.qrCodeLoading.hide();
                this._domElements.qrCodeImageContent.show();
                // set expire time for qr code
                this._timer = setTimeout(() => {
                    this._qrcodeHub.stopConnection(() => { });
                }, 120 * 1000); // 2 minutes
            });
        };
        this._onShowUserInfo = (message) => {
            // when qrcode is scanned, dont need to set expired qrcode
            clearTimeout(this._timer);
            // hide elements
            this._domElements.qrCodeImageContent.hide();
            this._domElements.qrCodeHiddens.hide();
            // show login message
            this._domElements.userInfoText.html(message);
            this._domElements.userInfoContent.show();
        };
        this._onConfirmLogin = (isConfirm, token) => {
            if (!isConfirm) {
                return this._qrcodeHub.stopConnection(() => {
                    location.reload();
                    //this._qrcodeHub.startConnection(this._startConnection);
                });
            }
            this._domElements.loginTokenHiddenField.val(token);
            this._domElements.loginForm.submit();
        };
        this._onCloseConnection = () => {
            this._domElements.qrCodeExpiredContent.show();
        };
        this._domElements = {
            connectionIdHiddenField: $("#qrcode-login-connection-id"),
            loginTokenHiddenField: $("#qrcode-login-token"),
            loginForm: $("#qrcode-login-form"),
            userInfoContent: $("#qrcode-user-info"),
            userInfoText: $("#qrcode-user-info b"),
            qrCodeImageContent: $("#qrcode-image-content"),
            qrCodeImage: $("#qrcode-image"),
            qrCodeExpiredContent: $(".qrcode-expired-content"),
            qrCodeNewBtn: $("#qrcode-new-btn"),
            qrCodeLoading: $(".qrcode-loading-content .lds-facebook"),
            qrCodeLoginForm: $("#qrcode-login-form"),
            // all elements need to hidden when scanned qrcode
            qrCodeHiddens: $(".qr-code-hiddens")
        };
        this._qrcodeHub = new qrcode_login_hub_1.QRCodeLoginHub();
    }
    initialize() {
        this._qrcodeHub.startConnection(this._startConnection);
        this._qrcodeHub.onShowUserLoginInfo(this._onShowUserInfo);
        this._qrcodeHub.onConfirmLogin(this._onConfirmLogin);
        this._qrcodeHub.onCloseConnection(this._onCloseConnection);
        this._domElements.qrCodeNewBtn.click(() => {
            this._domElements.qrCodeLoading.show();
            this._domElements.qrCodeExpiredContent.hide();
            this._domElements.qrCodeImage.attr("src", "");
            this._qrcodeHub.startConnection(this._startConnection);
        });
    }
}
exports.SignalRClient = SignalRClient;
new SignalRClient().initialize();
